export const setCssEasy = () => {
  const root = document.documentElement

  /* Colors */

  root.style.setProperty("--color-blue-dark", "#023047")
  root.style.setProperty("--color-blue", "#219EBC")
  root.style.setProperty("--color-blue-light", "#E9F5F8")

  root.style.setProperty("--color-gray", "#E6EAED")

  root.style.setProperty("--color-red-dark", "")
  root.style.setProperty("--color-red", "")

  root.style.setProperty("--color-orange-dark", "#D46A00")
  root.style.setProperty("--color-orange", "#FB8500")

  /* Texts */
  root.style.setProperty("--color-text-input-label", "#023047")

  /* Buttons */

  /* error */
  root.style.setProperty("--color-bg-button-error", "#FB8500")
  root.style.setProperty("--color-border-button-error", "#FB8500")
  root.style.setProperty("--color-text-button-error", "#ffffff")

  root.style.setProperty("--color-bg-button-error-disabled", "#D46A00")
  root.style.setProperty("--color-border-button-error-disabled", "#D46A00")
  root.style.setProperty("--color-text-button-error-disabled", "#ffffff")

  /* light underline */
  root.style.setProperty("--color-bg-button-light-underline", "")
  root.style.setProperty("--color-border-button-light-underline", "")
  root.style.setProperty("--color-text-button-light-underline", "")

  root.style.setProperty("--color-bg-button-disabled", "")
  root.style.setProperty("--color-border-button-disabled", "")
  root.style.setProperty("--color-text-button-disabled", "")
}

// export const setCssGo = () => {
//   const root = document.documentElement

//   /* Colors */

//   root.style.setProperty("--color-blue-dark", "#023047")
//   root.style.setProperty("--color-blue", "#219EBC")
//   root.style.setProperty("--color-blue-light", "#E9F5F8")

//   root.style.setProperty("--color-gray", "#E6EAED")

//   root.style.setProperty("--color-red-dark", "")
//   root.style.setProperty("--color-red", "")

//   root.style.setProperty("--color-orange-dark", "#D46A00")
//   root.style.setProperty("--color-orange", "#FB8500")

//   /* Texts */
//   root.style.setProperty("--color-text-input-label", "#023047")

//   /* Buttons */

//   /* error */
//   root.style.setProperty("--color-bg-button-error", "#FB8500")
//   root.style.setProperty("--color-border-button-error", "#FB8500")
//   root.style.setProperty("--color-text-button-error", "#ffffff")

//   root.style.setProperty("--color-bg-button-error-disabled", "#D46A00")
//   root.style.setProperty("--color-border-button-error-disabled", "#D46A00")
//   root.style.setProperty("--color-text-button-error-disabled", "#ffffff")

//   /* light underline */
//   root.style.setProperty("--color-bg-button-light-underline", "")
//   root.style.setProperty("--color-border-button-light-underline", "")
//   root.style.setProperty("--color-text-button-light-underline", "")

//   root.style.setProperty("--color-bg-button-disabled", "")
//   root.style.setProperty("--color-border-button-disabled", "")
//   root.style.setProperty("--color-text-button-disabled", "")
// }
