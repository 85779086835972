import "./services.scss"

import AirplaneIcon from "../icons/AirplaneIcon"
import TicketIcon from "../icons/TicketIcon"
import SpaIcon from "../icons/SpaIcon"
import ShippingIcon from "../icons/ShippingIcon"
import AssignmentIcon from "../icons/AssignmentIcon"
import { SourceType } from "../../context/comparator/types"
import { globalConfig } from "../../config"

const Services = () => {
  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName
  return (
    <div className={`service-element-container ${specificClassName}`}>
      <div className="service-element">
        <div className="icon s80">
          <AirplaneIcon
            color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
          />
        </div>
        <span className="text">
          <p className="dark">
            <span>Pour vos voyages : </span>via nos agences de voyage
            partenaires, nous vous aidons à trouver un hôtel parfait pour votre
            séjour, un chauffeur pour vous y emmener…
          </p>
        </span>
      </div>

      <div className="service-element">
        <div className="icon s80">
          <TicketIcon
            color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
          />
        </div>
        <span className="text">
          <p className="dark">
            <span>Pour vos sorties : </span>votre assistant personnel vous
            réservera votre table au restaurant, vos sièges pour voir vos pièces
            de théâtre, vos places de concert, vos places pour le match à venir…
          </p>
        </span>
      </div>

      <div className="service-element">
        <div className="icon s80">
          <SpaIcon color={source.includes("-easy") ? "#219EBC" : "#1C50D0"} />
        </div>
        <span className="text">
          <p className="dark">
            <span>Pour vos rendez-vous : </span>nous nous occupons de vos
            rendez-vous chez le coiffeur, chez le masseur pour votre bien-être,
            chez le docteur ou tout autre praticien…
          </p>
        </span>
      </div>

      <div className="service-element">
        <div className="icon s80">
          <ShippingIcon
            color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
          />
        </div>
        <span className="text">
          <p className="dark">
            <span>Pour vous faire aider : </span>
            votre assistant personnel peut s’occuper de vous trouver des
            nounous, des aides ménagères, un plombier, un professeur d’anglais,
            une assistante de vie…
          </p>
        </span>
      </div>

      <div className="service-element">
        <div className="icon s80">
          <AssignmentIcon
            color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
          />
        </div>
        <span className="text">
          <p className="dark">
            <span>Pour vous faire aider : </span>votre assistant personnel peut
            s’occuper de vous trouver des nounous, des aides ménagères, un
            plombier, un professeur d’anglais, une assistante de vie…
          </p>
        </span>
      </div>
    </div>
  )
}

export default Services
