import { ArrowLeft } from "react-feather"
import "./legales.scss"
import { Link } from "react-router-dom"

const TermsUseLegalNotices = () => {
  return (
    <div className="container">
      <div className="header">
        <Link to="/" className="btn red">
          <ArrowLeft size={36} />
        </Link>
        <h1>
          <strong>Conditions générales de vente et mentions légales</strong>
        </h1>
      </div>
      <p>
        <strong>Préambule / Informations générales</strong>
      </p>
      <p>
        Les présentes mentions légales ont pour but de préciser les conditions
        générales et les modalités de la prestation des services par la Société{" "}
        {process.env.REACT_APP_ORGANISATION} sur son site internet.
      </p>
      <p className="title">
        <strong>1 – Introduction et Mentions Légales</strong>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          {process.env.REACT_APP_SITENAME} est une marque opérée par{" "}
          {process.env.REACT_APP_ORGANISATION}, ci-après dénommée “Société”
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          La Société est une Société à Responsabilité Limitée (« Private Limited
          Company »), au capital social de 1£, immatriculée au registre du
          commerce et des Sociétés de Londres ous le numéro 13943058 et dont le
          siège est situé à {process.env.REACT_APP_ADDRESS}.
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          La Société peut être contactée :
          <ul>
            <li style={{ listStyleType: "circle" }}>
              par courrier électronique : {process.env.REACT_APP_EMAIL}
            </li>
          </ul>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              par téléphone au numéro suivant :{" "}
              {process.env.REACT_APP_ORGANISATION_PHONE}
            </li>
          </ul>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              par courrier à l’adresse du siège cité plus haut
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Directeur de la publication : Chloe Ellen MacKay
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Informations Hébergeur : site hébergé par Hostinger, basé en France.
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          La Société propose un service de conciergerie permettant aux
          utilisateurs de bénéficier des services d’un opérateur pour les
          assister dans différentes tâches du quotidien comme réserver une
          prestation ou se faire livrer un bien ou autres services décrits ci
          dessous, en contrepartie de l’adhésion à un abonnement.
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Les présentes CGVU s’appliquent dés la première utilisation du site
          internet et représentent un accord entre Vous et{" "}
          {process.env.REACT_APP_ORGANISATION}. Ces CGVU (Conditions Générales
          de Vente et d’Utilisation) sont disponibles en permanence sur le site
          internet et auront force de loi sur toute autre version ou tout autre
          document en contradiction avec celles-ci, le cas échéant.
        </li>
      </ul>
      <p className="title">
        2 – <strong>Définitions</strong>
      </p>
      <p>
        Chaque terme débutant ci-après par une majuscule a le sens qui lui est
        donné dans sa définition.
      </p>
      <p>
        <strong>Client, Vous ou Utilisateur :</strong> toute personne physique
        majeure ou personne morale qui accède, visite ou utilise les services du
        sites internet
      </p>
      <p>
        <strong>Espace Client :</strong> espace personnel mis à disposition du
        Client par la Société sur le Site Internet. Celui- ci est accessible
        grâce à un identifiant et un mot de passe personnels qu’il lui sont
        fournis par la Société, contenant notamment les données d’identification
        du Client. L’Espace Client est accessible sur le Site Internet à la
        rubrique « Espace Client » ;
      </p>
      <p>
        <strong>Jours et Heures ouvrés :</strong> Ces horaires sont donnés à
        titre indicatif et peuvent varier – du lundi au vendredi de 9h à 19h et
        le samedi et dimanche de 8h à 17h.
      </p>
      <p>
        <strong>Services :</strong> tous les services fournis par la Société sur
        le Site Internet et décrits ci dessous dans la partie “Description des
        services” ;
      </p>
      <p>
        <strong>Site Internet :</strong> service électronique interactif édité
        par la Société, accessible notamment à l’adresse www.orga-call.com
      </p>
      <p className="title">
        <strong>3 – Acceptation – Modification des CGV/CGU</strong>
      </p>
      <p>
        En souscrivant aux Services offerts par la Société, le Client accepte
        sans réserve les CGU présentées. Il est à noter que les CGU peuvent être
        modifiées à tout moment. Dans ce cas, les CGU en vigueur au moment de
        l’utilisation ou de la souscription des Services par le Client sont
        celles qui sont accessibles sur le Site Internet de la Société.
      </p>
      <p>
        {process.env.REACT_APP_ORGANISATION} se réserve le droit de modifier, à
        tout moment et sans préavis, le Site ainsi que les CGU, notamment pour
        s’adapter aux évolutions du Site par la mise à disposition de nouvelles
        fonctionnalités ou la suppression ou la modification de fonctionnalités
        existantes.
      </p>
      <p>
        Il est vivement recommandé à l’utilisateur de consulter la dernière
        version des CGU avant toute navigation sur le Site. Si l’Utilisateur
        n’est pas d’accord avec les CGU, il ne doit pas utiliser le site.
      </p>
      <p className="title">
        4 – <strong>Description des services</strong>
      </p>
      <p>
        La Société {process.env.REACT_APP_ORGANISATION} propose un service de
        conciergerie accessible depuis le site www.orga-call.com à ses clients
        via un abonnement qui leur donne accès à une assistance personnalisée
        pour toutes leurs réservations, ainsi qu’à des conseils sur mesure.
      </p>
      <p>
        La priorité de la conciergerie est de simplifier la vie de ses clients
        en fournissant des services d’intermédiaire et de conseil. À cette fin,
        la Société agit uniquement en tant qu’intermédiaire entre les clients et
        les fournisseurs de services.
      </p>
      <p>
        Les Services offerts par la Société sur le Site Internet sont les
        suivants :
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Un service de conciergerie dédié
          <ul>
            <li style={{ listStyleType: "circle" }}>
              La prestation fournit par la Société permet au Client /
              Utilisateur d’accéder à une conciergerie offrant une multitude de
              services adaptés au Client, directement en ligne via une
              assistance personnalisée en ligne disponible sur son espace
              client.
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Un assistance dédiée
          <ul>
            <li style={{ listStyleType: "circle" }}>
              La Société fournit également une assistance téléphonique au Client
              pour répondre à toutes les questions qu’il pourrait avoir
              concernant le Site Internet et les Services proposés.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Ce service d’assistance est accessible durant les Jours et Heures ouvrés
        :
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          par téléphone au numéro suivant :{" "}
          {process.env.REACT_APP_ORGANISATION_PHONE} ;
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          par courrier électronique à l’adresse suivante :{" "}
          {process.env.REACT_APP_EMAIL}
        </li>
      </ul>
      <p>
        La Société s’engage à répondre aux demandes du Client dans les meilleurs
        délais durant les Jours et Heures Ouvrés dans le cadre du service
        d’assistance.
      </p>
      <p>
        Le Client comprend et accepte que cette assistance est limitée à
        l’utilisation du Site Internet et des Services et ne peut en aucun cas
        constituer un conseil juridique. L’assistance téléphonique n’a ni les
        compétences ni la capacité de fournir au Client une aide concernant la
        pertinence d’un service par rapport à ses besoins particuliers.
      </p>
      <p className="title">
        <strong>5 – Modalité de souscription aux Services</strong>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>L’Espace Client</li>
      </ul>
      <p>
        Afin de pouvoir accéder à sa conciergerie multi-services{" "}
        {process.env.REACT_APP_SITENAME}, le Client doit créer un Espace Client
        sur le Site Internet. La création de cet Espace Client implique
        l’acceptation des CGU ainsi que le paiement complet des Services. Pour
        ce faire, le Client doit fournir à la Société les informations
        nécessaires à son identification. Ces informations comprennent notamment
        une adresse e-mail valide qui servira d’identifiant pour l’Espace
        Client. Le Client doit s’assurer que cette adresse e-mail est en état de
        fonctionnement et doit la mettre à jour si nécessaire. Le Client est
        responsable de la véracité, de la sincérité et de la fiabilité des
        informations fournies dans son Espace Client et s’engage à les tenir à
        jour régulièrement. La Société ne peut être tenue responsable si le
        Client ne modifie pas ou ne met pas à jour les informations le
        concernant. Si une information fournie par le Client est erronée ou
        trompeuse, la Société se réserve le droit de suspendre ou de rompre
        toute relation contractuelle avec le Client, ainsi que de suspendre
        l’accès de ce dernier à son Espace Client.
      </p>
      <p>
        Une fois que le Client aura rempli toutes les conditions requises, y
        compris le paiement intégral des Services, l’Espace Client sera créé
        définitivement. La Société enverra au Client par courrier électronique
        ses codes d’accès personnels (identifiant et mot de passe).
      </p>
      <p>
        Le Client est tenu de garder ses identifiants et mots de passe
        confidentiels et de ne pas les divulguer à des tiers. Le Client est la
        seule personne autorisée à utiliser les Services avec ses identifiants
        et mots de passe. Si quelqu’un d’autre accède à l’Espace Client avec les
        identifiants et mots de passe du Client, cela sera considéré comme une
        utilisation par le Client lui-même et sous sa responsabilité.
      </p>
      <p>
        Si les identifiants et mots de passe du Client sont perdus, volés ou
        utilisés frauduleusement, le Client doit immédiatement en informer la
        Société et, si nécessaire, fournir une preuve d’identité sur demande de
        la Société.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          L’Utilisation de la conciergerie et des services
        </li>
      </ul>
      <p>Pour utiliser la conciergerie multi-services, le Client peut :</p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Se connecter à son Espace Client depuis la rubrique “Espace Client”
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Appeler un opérateur {process.env.REACT_APP_SITENAME} par téléphone,
          au numéro de téléphone qu’il aura reçu par SMS à la suite de son
          inscription
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Contacter un opérateur par Whatsapp (sur ce même numéro) ou par mail
        </li>
      </ul>
      <p>
        Le Client peut faire autant de demandes qu’il le souhaite. Le Client
        reconnaît que la conciergerie multi-services est à sa disposition
        personnelle et qu’aucun tiers ne doit y accéder. Le non-respect des
        obligations du Client telles que définies ci dessous constitue une
        violation grave des CGU qui entraîne leur résiliation sans droit à
        indemnité ou à des dommages-intérêts. Le Client reconnaît également que{" "}
        {process.env.REACT_APP_SITENAME} s’efforcera de répondre au mieux aux
        besoins du Client et de ses demandes.
      </p>
      <p>
        Toutefois, {process.env.REACT_APP_SITENAME} a une obligation de moyens
        et ne peut garantir une issue favorable à chaque demande.
      </p>
      <p className="title">
        <strong>6 – Mode de paiement et tarifs</strong>
      </p>
      <p>
        La Société décline toute responsabilité pour les processeurs de paiement
        tiers, leurs conditions de service ou leurs politiques de
        confidentialité.
      </p>
      <p>
        A titre d’exemple, la Société utilise notamment le service de paiement
        sécurisé fourni par la Société Stripe, dont vous pouvez consulter les
        conditions de service de Stripe ainsi que leur politique de
        confidentialité en cliquant sur ce lien (
        <a href="https://stripe.com/fr-gb/privacy">
          Services Agreement – France | Stripe | France
        </a>
        ). En acceptant nos conditions, vous acceptez également que Stripe
        utilise vos données personnelles conformément à leurs politiques de
        confidentialité.
      </p>
      <p>
        La Société se réserve le droit de changer de processeur de paiement
        tiers, est n’est aucunement tenu de conserver Stripe qui est mentionné
        ici à titre d’exemple.
      </p>
      <p>
        Les tarifs affichés sur le site internet incluent toutes les taxes et
        sont en euros.
      </p>
      <p>
        La Société peut modifier les prix à tout moment. Les tarifs applicables
        sont ceux en vigueur sur le site internet à la date de création de
        l’Espace Client et d’accès aux Services.
      </p>
      <p>
        Le paiement des Services doit être effectué par carte bancaire
        uniquement.
      </p>
      <p className="title">
        <strong>7 – Modalités de l’abonnement</strong>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>Objet</li>
      </ul>
      <p>
        Le paiement de l’abonnement n’accorde qu’un droit d’accès à la
        conciergerie {process.env.REACT_APP_SITENAME}. Il ne rémunère aucun
        service quel qu’il soit, et en particulier aucun service
        d’intermédiation (transparente ou non). Des frais supplémentaires « à
        l’acte » en rémunération de services d’entremise apportée par la Société
        pourront être appliqués en sus. Dans ce dernier cas, les frais appliqués
        seront toujours annoncés et validés par le Client en amont du paiement.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>Prix</li>
      </ul>
      <p>
        Le prix indiqué sur le site internet www.orga-call.com est exprimé en
        euros, toutes taxes comprises, pour un accès forfaitaire aux services de
        la conciergerie {process.env.REACT_APP_SITENAME}. Nous pouvons modifier
        le prix de notre service à tout moment; toutefois, toute modification de
        prix sera notifiée par email. Si vous ne souhaitez pas accepter le
        changement de prix ou la modification de votre formule d’abonnement,
        vous pouvez annuler votre abonnement avant que le changement ne prenne
        effet ou demander un remboursement dans les 10 jours suivants la
        modification du tarif.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>Durée</li>
      </ul>
      <p>
        Le Client souscrit pour une durée de 1 mois (30 jours – trente jours) à
        partir de son premier paiement.
      </p>
      <p>
        Si une période d’essai est proposée, les Services sont souscrits pour
        une durée déterminée, comme indiqué pendant la période d’essai.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>Reconduction</li>
      </ul>
      <p>
        Cette période sera automatiquement renouvelée pour une période
        identique, sauf en cas de résiliation par le Client, qui peut le faire à
        tout moment.
      </p>
      <p>
        Si aucune résiliation n’a été effectuée dans les délais impartis lors de
        la période d’essai, les Services seront reconduits pour la durée
        indiquée.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>Résiliation</li>
      </ul>
      <p>Pour résilier les Services, le Client peut :</p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          accéder à son Espace Client et sélectionner l’option “se désabonner”.
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          en faire la demande par téléphone au numéro suivant : 01 89 71 39 71
          (appel non surtaxé) ;
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          en faire la demande par courrier électronique à l’adresse suivante :{" "}
          {process.env.REACT_APP_EMAIL}
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Garantie Satisfait ou Remboursé
        </li>
      </ul>
      <p>
        Lors du premier mois, et ce même si la période d’essai est révolue, si
        le Client est insatisfait, il peut demander une résiliation accompagnée
        d’une demande de remboursement qui sera systématiquement acceptée et
        sans réserve.
      </p>
      <p>
        Si cette demande est effectuée alors que le paiement du second mois a
        déjà été effectué, la Société se réserve le droit d’accepter ou non la
        demande du Client.
      </p>
      <p className="title">
        <strong>8 – Propriété Intellectuelle</strong>
      </p>
      <p>
        La Société détient tous les droits de propriété intellectuelle associés
        au Site Internet.
      </p>
      <p>
        L’accès du Client au Site Internet ne lui confère aucun droit sur les
        droits de propriété intellectuelle liés au Site Internet, qui restent la
        propriété exclusive de la Société.
      </p>
      <p>
        Le Client s’engage à ne pas reproduire, modifier, transmettre, publier,
        adapter ou exploiter, sur quelque support que ce soit et de quelque
        manière que ce soit, tout ou partie du Site Internet sans l’autorisation
        écrite préalable de la Société.
      </p>
      <p>
        Le contenu du site internet (textes, images, logos, etc.) est protégé
        par les lois sur la propriété intellectuelle et est la propriété de{" "}
        {process.env.REACT_APP_ORGANISATION} ou de ses partenaires. Toute
        reproduction, même partielle, est strictement interdite sans
        l’autorisation préalable de {process.env.REACT_APP_ORGANISATION}.
      </p>
      <p className="title">
        <strong>9 – Accès au site internet</strong>
      </p>
      <p>
        La Société met tout en œuvre pour garantir la disponibilité permanente
        de son site internet, 24 heures sur 24 et 7 jours sur 7, en dehors des
        périodes de maintenance planifiées pour le site et/ou les serveurs qui
        l’hébergent.
      </p>
      <p>
        Cependant, la Société se réserve le droit de modifier ou de suspendre
        temporairement, sans préavis ni compensation, une partie ou la totalité
        du site internet pendant une période raisonnable.
      </p>
      <p className="title">
        <strong>
          10 – Obligations, Responsabilités de la Société et de l’Utilisateur
        </strong>
      </p>
      <p>
        La Société s’engage à fournir un service de qualité en faisant preuve de
        tout le soin et la diligence nécessaires. Cependant, elle n’est tenue
        qu’à une obligation de moyens pour que les prestations soient effectuées
        conformément aux règles professionnelles et aux conditions de la
        commande.
      </p>
      <p>
        La responsabilité civile et pénale de la Société ne sera pas engagée en
        cas de faute de l’Utilisateur, telle que l’utilisation inappropriée du
        service ou la divulgation ou l’utilisation illicite du compte créé. En
        cas de litige, la Société s’engage à communiquer toutes les informations
        nécessaires sur le prestataire utilisé.
      </p>
      <p>
        {process.env.REACT_APP_SITENAME} décline toute responsabilité concernant
        l’établissement de l’estimation et du devis transmis par l’Utilisateur
        et le Professionnel respectivement. Elle ne peut également être tenue
        responsable des engagements pris par les Professionnels ou de leurs
        manquements dans l’exécution, le retard ou la mauvaise exécution des
        Prestations.
      </p>
      <p>
        La Société ne peut pas garantir un Service exempt de tout défaut, et
        l’Utilisateur accepte que le Service peut être temporairement
        indisponible en raison de défauts techniques ou d’autres événements sur
        lesquels la Société n’a aucun contrôle.
      </p>
      <p>
        La Société peut modifier ou interrompre temporairement ou définitivement
        tout ou partie du Site pour effectuer des travaux de maintenance ou des
        améliorations, et elle ne sera pas responsable de toute modification,
        suspension ou interruption du Site.
      </p>
      <p>
        La Société, agissant sous la marque {process.env.REACT_APP_SITENAME},
        n’est pas une agence de voyage et n’agit qu’en qualité d’intermédiaire
        dans la vente de prestations de transport, d’hébergement ou de tout
        autre type de prestations. En ce qui concerne les prestations de
        transport, {process.env.REACT_APP_SITENAME}
        travaille avec des agences de voyages agréées et les titres acquis font
        l’objet d’un contrat distinct liant contractuellement l’agence de voyage
        partenaire et le Client. La responsabilité contractuelle de la Société
        ne saurait être engagée pour une faute résultant du contrat de
        transport, d’hébergement ou de tout autre type de prestations.
      </p>
      <p className="title">
        <strong>11 – Protection des données personnelles</strong>
      </p>
      <p>
        Les données personnelles collectées sur le site internet sont utilisées
        uniquement pour le traitement des demandes des utilisateurs et ne sont
        en aucun cas transmises à des tiers sans l’accord préalable de
        l’utilisateur.
      </p>
      <p>
        Conformément à la loi “Informatique et Libertés”, les utilisateurs
        disposent d’un droit d’accès, de rectification et de suppression de
        leurs données personnelles.
      </p>
      <p>
        Le responsable du traitement des données à caractère personnel du Client
        est la Société.
      </p>
      <p>
        La collecte et le traitement des données personnelles de l’utilisateur
        sont effectués à différentes étapes de son parcours sur le site
        www.orga-call.com, notamment lors de son inscription et de l’ouverture
        de son compte utilisateur, pendant ses actions en ligne sur le site,
        ainsi que pour toute opération de paiement effectuée sur
        www.orga-call.com. Les opérations de traitement et de collecte sont
        effectuées directement auprès de l’utilisateur, sur demande et pendant
        l’utilisation du site www.orga-call.com.
      </p>
      <p>
        Lorsqu’il utilise le service www.orga-call.com, l’utilisateur est
        informé que des données personnelles peuvent être collectées.
      </p>
      <p>Les types de données pouvant être collectées sont les suivants :</p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Les données d’identification et de facturation telles que le nom, le
          prénom, l’adresse et l’e-mail, entre autres,
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Les données bancaires pour faciliter les paiements,
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Les données commerciales telles que les conversations avec le service
          clientèle ou les commentaires,
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          Les données de facturation et de paiement,
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>Les données de connexion.</li>
      </ul>
      <p>
        Il convient de noter qu’un astérisque indique les données qui doivent
        obligatoirement être collectées et qui seront potentiellement utilisées
        et traitées. En l’absence de ce symbole, la collecte de ces données
        n’est pas nécessairement effectuée.
      </p>
      <p>
        Conformément aux dispositions des articles 38, 39 et 40 de la Loi
        Informatique &amp; Libertés, le Client a, à tout moment, la faculté de :
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          s’opposer au traitement de ses données à caractère personnel dans le
          cadre des Services fournis par la Société (article 38 de la Loi
          Informatique &amp; Libertés) ;
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          s’opposer à la communication de ses données à caractère personnel à
          des tiers ;
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          accéder à l’ensemble de ses données à caractère personnel traitées
          dans le cadre des Services fournis par la Société (article 39 de la
          Loi Informatique &amp; Libertés) ;
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          rectifier, mettre à jour et supprimer ses données à caractère
          personnel traitées dans le cadre des Services fournis par la Société
          (article 40 de la Loi Informatique &amp; Libertés).
        </li>
      </ul>
      <p>
        En outre, le Client a la possibilité de définir auprès de la Société des
        directives relatives à la conservation, à l’effacement et à la
        communication de ses données à caractère personnel après son décès,
        lesquelles directives peuvent être enregistrées également auprès « d’un
        tiers de confiance numérique certifié ». Ces directives, ou sorte de «
        testament numérique », peuvent désigner une personne chargée de leur
        exécution ; à défaut, les héritiers du Client seront désignés.
      </p>
      <p>
        En l’absence de toute directive, les héritiers peuvent s’adresser à la
        Société afin de :
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          accéder aux traitements de données permettant « l’organisation et le
          règlement de la succession du défunt »;
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          recevoir communication des « biens numériques » ou des « données
          s’apparentant à des souvenirs de famille, transmissibles aux héritiers
          » ;
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          faire procéder à la clôture de son Espace Client et s’opposer à la
          poursuite du traitement de ses données à caractère personnel.
        </li>
      </ul>
      <p>
        En tout état de cause, le Client a la possibilité d’indiquer, à tout
        moment, à la Société qu’il ne souhaite pas, en cas de décès, que ses
        données à caractère personnel soient communiquées à un tiers.
      </p>
      <p>
        Pour exercer ses droits, il suffit au Client d’adresser un simple
        courrier à la Société ou un courrier électronique, en prenant soin de
        justifier de son identité (mentionner ses noms, prénoms, adresse de
        courrier électronique et joindre une copie de sa pièce d’identité),
        conformément aux alinéas 1ers des articles 39 et 40 la Loi Informatique
        &amp; Libertés.
      </p>
      <p>
        La Société collecte et traite les données à caractère personnel du
        Client avec la plus grande confidentialité, et dans le respect des
        dispositions de la Loi Informatique &amp; Libertés.
      </p>
      <p>
        La Société s’engage à prendre toutes mesures raisonnables nécessaires à
        la sécurisation et la protection des données à caractère personnel du
        Client, collectées et traités par ses soins.
      </p>
      <p>
        L’ensemble des données à caractère personnel du Client font l’objet d’un
        archivage électronique par la Société, durant toute la durée de
        l’inscription de l’accès aux Services sous les conditions visées aux
        présentes CGU et sont supprimées, une fois son Espace Client supprimé.
        Elles peuvent cependant faire l’objet d’un archivage intermédiaire au
        sens de la la Loi Informatique &amp; Libertés.
      </p>
      <p className="title">
        <strong>12 – Cookies</strong>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <strong>Qu’est-ce qu’un cookie ?</strong>
        </li>
      </ul>
      <p>
        Les cookies sont de petits fichiers que les Sites Web déposent et
        stockent sur votre navigateur internet. Ils peuvent contenir des données
        telles que vos informations de connexion, vos préférences de langue, ou
        les articles que vous avez ajoutés à votre panier. Nous utilisons les
        cookies pour nous assurer que le Site Web fonctionne correctement et
        pour améliorer votre expérience utilisateur lors de votre navigation.
        Les cookies nous permettent également de mesurer l’audience de notre
        Site Web et d’adapter notre Site Web à vos préférences.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <strong>
            Comment pouvez-vous accepter ou refuser l’utilisation d’un cookie ?
          </strong>
        </li>
      </ul>
      <p>
        Les cookies sont configurables directement via la solution Complianz
        intégrée sur notre Site Web, qui vise à simplifier l’acceptation, le
        rejet ou la gestion des cookies.
      </p>
      <p>
        Lors de votre première visite et jusqu’à la collecte de votre décision
        concernant les cookies, une boîte de dialogue s’affiche pour vous
        inviter à communiquer votre décision concernant les cookies. Une fois
        votre décision prise, vous pouvez à tout moment modifier la
        configuration des cookies en cliquant sur le bouton situé en bas à
        gauche de votre écran, présent sur l’ensemble des pages du Site.
      </p>
      <p>
        Si vous souhaitez refuser l’utilisation totale des cookies, vous devez
        régler les paramètres de votre navigateur. Chaque navigateur étant
        différent, nous vous recommandons de consulter le menu « Aide » de votre
        navigateur pour voir comment vous pouvez modifier vos préférences en
        matière de cookies. Le refus des cookies peut entraver le bon
        fonctionnement de certains services personnalisés, empêchant ainsi une
        utilisation complète de certaines fonctionnalités de notre Site Web.
      </p>
      <p>
        Lors de votre navigation sur le Site Web, nous recueillons,
        indépendamment de l’utilisation des cookies, des informations vous
        concernant, notamment les informations relatives à votre système
        d’exploitation, la version du navigateur, le nom de domaine et l’adresse
        IP, la dernière page Web visitée et les pages du Site Web que vous
        visitez. Nous pouvons utiliser ces informations à des fins marketing ou
        promotionnelles.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <strong>Comment utilisons-nous les cookies ?</strong>
        </li>
      </ul>
      <p>
        Les informations récupérées par les cookies peuvent nous aider à
        analyser votre utilisation du Site Web dans le but de vous offrir la
        meilleure expérience utilisateur. Les informations collectées par les
        cookies peuvent être utilisées dans un but d’identification. En aucun
        cas, nous n’utilisons les cookies dans une démarche de publicité ciblée
        et personnalisée à destination des enfants.
      </p>
      <p className="title">
        <strong>13 – Réclamations</strong>
      </p>
      <p>
        Si le client a des problèmes techniques ou des questions concernant le
        fonctionnement du site internet et/ou des services, il est recommandé
        qu’il envoie une demande à l’adresse de la Société, que ce soit par
        courrier électronique ou par courrier postal.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <strong>Droit Applicable – Preuves</strong>
        </li>
      </ul>
      <p>
        Les présentes Conditions seront exclusivement régies et interprétées
        conformément au droit du Royaume-Uni, à l’exclusion de ses règles de
        conflit de lois, à moins que, si vous résidez dans l’UE, la
        réglementation en matière de protection des consommateurs de votre pays
        de résidence ne contienne des dispositions plus avantageuses pour vous,
        auquel cas ces dispositions s’appliquent. La Convention de Vienne sur la
        vente internationale de marchandises de 1980 (CVIM) n’est pas
        applicable. Vous pouvez introduire une action en justice relative aux
        Services devant un tribunal britannique compétent.
      </p>
      <p>
        À moins qu’il n’y ait des dispositions légales contraires, tout
        différend entre {process.env.REACT_APP_ORGANISATION} et ses utilisateurs
        sera résolu par le tribunal compétent dans la région où se trouve le
        siège social de {process.env.REACT_APP_ORGANISATION}.
      </p>
      <p>
        Les données collectées via le site internet seront considérées comme
        ayant une force probante, notamment si elles sont produites par{" "}
        {process.env.REACT_APP_ORGANISATION} dans le cadre d’une procédure
        judiciaire ou autre. Elles seront considérées comme étant recevables,
        valables et opposables aux parties, avec la même force probante que tout
        document établi, reçu ou conservé par écrit, sauf en cas d’erreur
        manifeste du site.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <strong>Règlement des litiges</strong>
        </li>
      </ul>
      <p>
        En cas de litige lié à ces CGU, leur interprétation ou leurs
        conséquences, le Client doit d’abord contacter la Société pour tenter de
        trouver une solution amiable.
      </p>
      <p>Si aucune solution amiable n’est trouvée, le Client peut choisir :</p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          De recourir à une solution de médiation amiable dans un délai maximum
          d’un (1) an à compter de sa réclamation écrite adressée à la Société,
          en saisissant un médiateur. Il est important de noter que le processus
          de médiation proposé ne doit pas être une condition préalable à une
          saisie des tribunaux compétents par le Client.
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          De porter sa réclamation devant les tribunaux britanniques compétents.
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <strong>Intégralité des mentions légales</strong>
        </li>
      </ul>
      <p>
        Les présentes CGU contiennent toutes les obligations de la Société et du
        Client. Si l’une des parties ne fait pas valoir le manquement de l’autre
        partie à l’une de ses obligations mentionnées dans les CGU, cela ne doit
        pas être interprété comme une renonciation à cette obligation à
        l’avenir.
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <strong>Non validité partielle</strong>
        </li>
      </ul>
      <p>
        Si une ou plusieurs clauses des présentes CGU sont considérées comme
        nulles, non écrites ou déclarées comme telles par une loi, un règlement
        ou une décision d’une juridiction compétente ayant autorité de la chose
        jugée en dernier ressort, les autres clauses resteront en vigueur et
        seront pleinement applicables. Toutefois, si les clauses invalides
        étaient substantielles et que leur suppression remettait en cause
        l’équilibre contractuel, cela pourrait être remis en question.
      </p>
      <p className="title">
        <strong>
          En acceptant les présentes Conditions, vous acceptez d’être lié(e) par
          elles.
        </strong>
      </p>
      <p>{process.env.REACT_APP_SITENAME}</p>
      <p>{process.env.REACT_APP_ORGANISATION_PHONE}</p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          {process.env.REACT_APP_SITENAME}
        </li>
      </ul>
    </div>
  )
}

export default TermsUseLegalNotices
