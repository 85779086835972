import React from "react"
import UserCheckIcon from "../icons/UserCheckIcon"
import EuroIcon from "../icons/EuroIcon"
import StarIcon from "../icons/StarIcon"
import "./offerInfoList.scss"
import { SourceType } from "../../context/comparator/types"
import { globalConfig } from "../../config"

const OfferInfoList = () => {
  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName
  return (
    <div className={`offer-wrapper ${specificClassName}`}>
      <div className="offer-info">
        <div className="offer-icon">
          <UserCheckIcon
            color={source.includes("-easy") ? "#219EBC" : "#2571FE"}
          />
        </div>
        <p className="offer-name">Assistant voyage dédié 7/7</p>
      </div>
      <div className="offer-info">
        <div className="offer-icon">
          <EuroIcon color={source.includes("-easy") ? "#219EBC" : "#2571FE"} />
        </div>
        <p className="offer-name">Validation des meilleurs prix</p>
      </div>
      <div className="offer-info">
        <div className="offer-icon">
          <StarIcon color={source.includes("-easy") ? "#219EBC" : "#2571FE"} />
        </div>
        <p className="offer-name">Transports, Hôtels, Restaurants, ...</p>
      </div>
    </div>
  )
}

export default OfferInfoList
