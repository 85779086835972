import { useMemo, useState } from "react"
import PhoneIncomingIcon from "../icons/PhoneIncomingIcon"
import PhoneOutGoingIcon from "../icons/PhoneOutGoingIcon"
import WhatsAppIcon from "../icons/WhatsAppIcon"

import "./assistantButtons.scss"
import LoaderIcon from "../icons/LoaderIcon"
import CloseIcon from "../icons/CloseIcon"
import ChevronRightIcon from "../icons/ChevronRightIcon"
import { SourceType } from "../../context/comparator/types"
import { globalConfig } from "../../config"

type AssistantButtonsProps = {
  hrefAssistant?: string
  onClickAssistant?: () => void
  hrefCall?: string
  onClickCall?: () => void
  hrefWhatsApp?: string
  onClickWhatsApp?: () => void
  column?: boolean
}

const AssistantButtons = ({
  hrefAssistant,
  onClickAssistant,
  hrefCall,
  onClickCall,
  hrefWhatsApp = "https://wa.me/+33757810747",
  onClickWhatsApp,
  column,
}: AssistantButtonsProps) => {
  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  const [openCallMeBackModal, setOpenCallMeBackModal] = useState<boolean>(false)

  const handleClickAssistant = () => {
    setOpenCallMeBackModal(true)
    if (onClickAssistant) {
      onClickAssistant()
    }
  }

  const assistantProps: any = useMemo(() => {
    const props: any = {}
    if (hrefAssistant) {
      props.href = hrefAssistant
    }
    if (onClickAssistant) {
      props.onClick = handleClickAssistant
    } else {
      props.onClick = () => setOpenCallMeBackModal(true)
    }

    return props
  }, [hrefAssistant, onClickAssistant])

  const callProps: any = useMemo(() => {
    const props: any = {}
    if (hrefCall) {
      props.href = hrefCall
    }
    if (onClickCall) {
      props.onClick = onClickCall
    }

    return props
  }, [hrefCall, onClickCall])

  const whatsAppProps: any = useMemo(() => {
    const props: any = {}
    if (hrefWhatsApp) {
      props.href = hrefWhatsApp
    }

    if (onClickWhatsApp) {
      props.onClick = onClickWhatsApp
    }

    return props
  }, [hrefWhatsApp, onClickWhatsApp])

  return (
    <>
      <div
        className={`button-grid ${column ? "column" : ""} ${specificClassName}`}
      >
        {!column ? (
          <a className="card-link" {...callProps}>
            <div className="icon">
              <PhoneOutGoingIcon
                color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
              />
            </div>
            <div className="name">Appeler {process.env.REACT_APP_SITENAME}</div>
          </a>
        ) : (
          <a className="card-link" {...callProps}>
            <div>
              <div className="icon">
                <PhoneOutGoingIcon
                  color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
                />
              </div>
              <div className="name">
                Appeler {process.env.REACT_APP_SITENAME}
              </div>
            </div>
            <ChevronRightIcon />
          </a>
        )}

        {!column ? (
          <a className="card-link" {...assistantProps}>
            <div className="icon">
              <PhoneIncomingIcon
                color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
              />
            </div>
            <div className="name">Me faire rappeler</div>
          </a>
        ) : (
          <a className="card-link" {...assistantProps}>
            <div>
              <div className="icon">
                <PhoneIncomingIcon
                  color={source.includes("-easy") ? "#219EBC" : "#1C50D0"}
                />
              </div>
              <div className="name">Me faire rappeler</div>
            </div>
            <ChevronRightIcon />
          </a>
        )}
        {/* {!column ? (
          <a className="card-link green" {...whatsAppProps}>
            <div className="icon">
              <WhatsAppIcon />
            </div>
            <div className="name">
              WhatsApp {process.env.REACT_APP_SITENAME}
            </div>
          </a>
        ) : (
          <a className="card-link green" {...whatsAppProps}>
            <div>
              <div className="icon">
                <WhatsAppIcon />
              </div>
              <div className="name">
                WhatsApp {process.env.REACT_APP_SITENAME}
              </div>
            </div>
            <ChevronRightIcon color="#009420" />
          </a>
        )} */}
      </div>

      <div
        className={`modal-backdrop ${openCallMeBackModal ? "visible" : ""}`}
        onClick={() => setOpenCallMeBackModal(false)}
      >
        <div
          className="assistant-modal"
          onClick={(event) => {
            event.stopPropagation() // Empêche la propagation de l'événement
          }}
        >
          <button
            type="button"
            className="close"
            onClick={() => setOpenCallMeBackModal(false)}
          >
            <CloseIcon />
          </button>
          <p className="title">Nous sommes en train de vous rappeler.</p>
          <p className="subtitle">Veuillez patienter.</p>

          <div className="loader-wrapper">
            <LoaderIcon />
          </div>
        </div>
      </div>
    </>
  )
}

export default AssistantButtons
