import { useEffect, useLayoutEffect, useRef } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import Layout from "./components/layout/Layout"
import Payment from "./components/pages/Payment"
import Confirm from "./components/pages/Confirm"
import TermsUseLegalNotices from "./components/pages/legales/TermsUseLegalNotices"
import PrivacyStatement from "./components/pages/legales/PrivacyStatement"

const Router = () => {
  const { pathname } = useLocation()

  const prevPathnameRef = useRef()

  useLayoutEffect(() => {
    if (prevPathnameRef.current !== pathname) {
      window.scrollTo(0, 0)
    }
    // @ts-ignore
    prevPathnameRef.current = pathname
  }, [pathname])

  return (
    <Routes>
      <Route path="/" element={<Layout withNavBar children={<Payment />} />} />
      <Route path="/confirm" element={<Layout children={<Confirm />} />} />

      <Route
        path="/terms-of-use-&-legal-notices"
        element={<Layout children={<TermsUseLegalNotices />} />}
      />
      <Route
        path="/privacy-statement"
        element={<Layout children={<PrivacyStatement />} />}
      />
    </Routes>
  )
}

export default Router
