import "./confirm.scss"
import { useContext, useEffect, useState } from "react"
import { PaymentContext } from "../../context/paymentContext"
import { SlackService } from "../../services/slackService"
import { ComparatorContext } from "../../context/comparator/context"
import AssistantButtons from "../subComponents/AssistantButtons"
import OfferInfoList from "../subComponents/OfferInfoList"
import DoubleArrowDown from "../icons/DoubleArrowDown"
import ArrowRightIcon from "../icons/ArrowRightIcon"
import { ReactComponent as CallCenterIcon } from "../../assets/images/call-center.svg"
import { ReactComponent as SncfLogo } from "../../assets/images/sncf.svg"
import { ReactComponent as OmioLogo } from "../../assets/images/omio.svg"
import { ReactComponent as TrainlineLogo } from "../../assets/images/trainline.svg"
import { ReactComponent as SncfLogoDesktop } from "../../assets/images/sncf-desktop.svg"
import { ReactComponent as OmioLogoDesktop } from "../../assets/images/omio-desktop.svg"
import { ReactComponent as TrainlineLogoDesktop } from "../../assets/images/trainline-desktop.svg"
import { initAmplitude, sendAmplitudeTrack } from "../../services/amplitude"
import { GlobalContext } from "../../context/globalContext"
import InfoIcon from "../icons/InfoIcon"
import CheckCircleIcon from "../icons/CheckCircleIcon"
import ArrowUpRight from "../icons/ArrowUpRight"
import { SourceType } from "../../context/comparator/types"
import { globalConfig } from "../../config"

const abCashback = false
const Confirm = () => {
  const [error, setError] = useState("")
  const { paymentState } = useContext(PaymentContext)
  const { comparatorState } = useContext(ComparatorContext)
  const { isMobile } = useContext(GlobalContext)

  const abVersion: "A" | "B" =
    (localStorage.getItem("version") as "A" | "B") || "A"

  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  const postPaymentDescription = `Inscription confirmée : cette transaction apparaîtra sur votre relevé bancaire sous l’intitulé “orga-call.com - ${source}”`

  const callMeBack = async () => {
    await SlackService.sendCallMeBack({
      source: comparatorState?.source || "train",
      source_url: comparatorState?.source_url || "",
      lastname: paymentState.customer.lastname,
      firstname: paymentState.customer.firstname,
      phone: paymentState.customer.phone,
      email: paymentState.customer.email,
      roundtrip:
        comparatorState?.departureDate && comparatorState?.returnDate
          ? true
          : false,
      departure: comparatorState?.from?.name || "",
      arrival: comparatorState?.to?.name || "",
      departureDate: comparatorState?.departureDate || "",
      returnDate: comparatorState?.returnDate || "",
      passengers: comparatorState?.passengers || 1,
      price: comparatorState?.price || 0,
    })
  }

  const getAmplitudeOptions = () => {
    if (window.innerWidth < 1024) {
      return {
        abTestName: "confirmPage",
        abVersion,
      }
    }

    return {}
  }

  const sendCallEvent = () => {
    sendAmplitudeTrack("Click - Confirm page - Call", getAmplitudeOptions())
  }

  const sendCallMeBackEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - Call me back",
      getAmplitudeOptions()
    )
  }

  const sendWhatsAppEvent = () => {
    sendAmplitudeTrack("Click - Confirm page - WhatsApp", getAmplitudeOptions())
  }

  const sendVisitOrgaCallEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - Visit website link",
      getAmplitudeOptions()
    )
  }

  const sendWithoutAssistantEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - No assistance",
      getAmplitudeOptions()
    )
  }

  const sendUnsubscribeClickEvent = () => {
    sendAmplitudeTrack(
      "Click - Confirm page - Unsubscribe link",
      getAmplitudeOptions()
    )
  }

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search)
    const adid = currentSearchParams.get("adid") ?? undefined
    const auid = currentSearchParams.get("auid") ?? undefined

    const source = localStorage.getItem("source") as SourceType

    const sendTrack = async () => {
      await initAmplitude(source, {
        userId: auid,
        deviceId: adid,
      }).promise

      sendAmplitudeTrack("View - Confirm page", getAmplitudeOptions())
    }

    sendTrack()
  }, [])

  return (
    <div id="confirm-page" className={specificClassName}>
      {((isMobile && abCashback && abVersion === "A") ||
        !isMobile ||
        abCashback === false) && (
        <div className="post-payment-description">
          <InfoIcon color={source.includes("-easy") ? "#023047" : "#06102A"} />
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: postPaymentDescription }}
          />
        </div>
      )}
      <div
        className="first-menu"
        onClick={(event) => {
          event.stopPropagation() // Empêche la propagation de l'événement
        }}
      >
        {isMobile && abCashback && abVersion === "B" && (
          <div className="mobile-view green-label-cashback">
            <div className="title">
              <div className="check-icon">
                <CheckCircleIcon />
              </div>
              <h3>Offre de bienvenue validée</h3>
            </div>

            <p>
              Pour vous faire rembourser 10€ après l’achat de votre billet
              veuillez vous rendre{" "}
              <a href="https://orga-call.com/cashback" target="_blank">
                ICI
              </a>
              <br />
              <br />
              Cette transaction apparaitra sur votre relevé bancaire sous
              l’intitulé “go-resa.com - {source}”
            </p>
          </div>
        )}
        <div
          className="red-header"
          style={{
            ...((isMobile && abCashback && abVersion === "B") ||
            abCashback === false
              ? { paddingTop: "20px" }
              : {}),
          }}
        >
          <h2>Billet non réservé !</h2>
          <h4>Finalisez votre réservation ci-dessous</h4>
          <div className="icon">
            <DoubleArrowDown />
          </div>
        </div>

        <div className="menu-content">
          <div className="mobile-view">
            <div className="external-links-wrapper">
              <a
                href="https://www.sncf.com/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <SncfLogo />
                </div>
                <span className="label">SNCF</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.omio.fr/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <OmioLogo />
                </div>
                <span className="label">Omio</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.thetrainline.com/fr"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <TrainlineLogo />
                </div>
                <span className="label">Trainline</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
            </div>
          </div>
          <div className="left-side-menu-content">
            <h4 className={`assistant-title-wrapper`}>
              <div className="icon">
                <CallCenterIcon />
              </div>

              <p className="assistant-title">
                <span>Votre assistant</span>
                <span className="big">{process.env.REACT_APP_SITENAME}</span>
              </p>
            </h4>

            <div>
              <AssistantButtons
                onClickAssistant={() => {
                  sendCallMeBackEvent()
                  callMeBack()
                }}
                hrefCall="tel:+331 89 71 39 71"
                onClickCall={sendCallEvent}
                onClickWhatsApp={sendWhatsAppEvent}
                column={isMobile && abVersion === "B"}
              />
            </div>

            <div className={`md-auto-center`} style={{ marginTop: "4px" }}>
              <OfferInfoList />
            </div>
            <a
              className={`visit-goresa md-auto-center`}
              href="https://orga-call.com/"
              target="_blank"
              onClick={sendVisitOrgaCallEvent}
            >
              Visiter le site {process.env.REACT_APP_SITENAME} {">"}
            </a>
          </div>
          <p className="assistant-or-text desktop-view">OU</p>
          <div className="desktop-view">
            <div className="external-links-wrapper">
              <a
                href="https://www.sncf.com/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <SncfLogoDesktop />
                </div>
                <span className="label">SNCF</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.omio.fr/"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <OmioLogoDesktop />
                </div>
                <span className="label">Omio</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
              <a
                href="https://www.thetrainline.com/fr"
                target="_blank"
                className="external-link-btn"
              >
                <div className="icon-wrapper">
                  <TrainlineLogoDesktop />
                </div>
                <span className="label">Trainline</span>
                <div className="icon-wrapper">
                  <ArrowUpRight color="#06102A" />
                </div>
              </a>
            </div>
            <p className="small">
              Si vous ne faites pas appel à la conciergerie au delà des 3 jours
              gratuits, pensez à vous désabonner sans frais en{" "}
              <a
                href="https://orga-call.com/abonnement"
                target="_blank"
                onClick={sendUnsubscribeClickEvent}
              >
                cliquant ici
              </a>
              .
            </p>
          </div>
        </div>
        <p className="small mobile-view">
          Si vous ne faites pas appel à la conciergerie au delà des 3 jours
          gratuits, pensez à vous désabonner sans frais en{" "}
          <a
            href="https://orga-call.com/abonnement"
            target="_blank"
            onClick={sendUnsubscribeClickEvent}
          >
            cliquant ici
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default Confirm
