import { IConfig } from "../../config"

export type SourceType =
  | "train"
  | "bus"
  | "avion"
  | "train-easy"
  | "bus-easy"
  | "avion-easy"
//   | "train-go"
//   | "bus-go"
//   | "avion-go"

export enum SourceTypeEnum {
  Train = "train",
  Bus = "bus",
  Avion = "avion",
  TrainEasy = "train-easy",
  BusEasy = "bus-easy",
  AvionEasy = "avion-easy",
  //   TrainGo = "train-go",
  //   BusGo = "bus-go",
  //   AvionGo = "avion-go",
}

export enum SourceTypeEnumLabel {
  train = "Train",
  bus = "Bus",
  avion = "Avion",
  "train-easy" = "Train-Easy",
  "bus-easy" = "Bus-Easy",
  "avion-easy" = "Avion-Easy",
  //   "train-go" = "Train-Go",
  //   "bus-go" = "Bus-Go",
  //   "avion-go" = "Avion-Go",
}

export interface Station {
  id?: number
  name: string
}

export interface ComparatorState {
  source: SourceType
  source_url: string
  config?: IConfig
  from: Station
  to: Station
  departureDate: string
  returnDate: string
  passengers: number
  price: number
}
